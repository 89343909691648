<template>
  <div>
    <div class="search-bar">
      <a-form-model
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        ref="searchRef"
        :model="searchForm"
      >
        <a-row>
          <a-col :span="8">
            <a-form-model-item prop="order_no" label="订单ID">
              <a-input allowClear v-model="searchForm.order_no" placeholder="请输入订单ID"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="applicant_phone" label="申请人手机号">
              <a-input allowClear v-model="searchForm.applicant_phone" placeholder="请输入手机号"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="reviewer_staff_id" label="审核人">
              <a-select
                allowClear
                show-search
                placeholder="请输入审核人"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @search="getStaffList"
                v-model="searchForm.reviewer_staff_id"
              >
                <a-select-option
                  v-for="(item, index) in staffList"
                  :key="index"
                  :value="item.staff_id"
                >{{item.staff_name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item prop="apply_time_arr" label="申请日期">
              <a-range-picker v-model="searchForm.apply_time_arr" valueFormat="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="audit_time_arr" label="审核日期">
              <a-range-picker v-model="searchForm.audit_time_arr" valueFormat="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8" class="text-right mt-1">
            <a-button type="primary" html-type="submit" @click="initData">搜索</a-button>
            <a-button style="margin-left: 10px" @click="resetForm">重置</a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </div>

    <base-table
      ref="refundTableRef"
      id="refundTableID"
      rowKey="id"
      :columnsData="filterColumns"
      :tableData="tableData"
      :total="total"
      :getListFunc="initData"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">订单退款列表</h4>
        </div>
        <div class="flex justify-between">
          <a-tabs
            type="card"
            class="w-full"
            v-model="searchForm.audit_status"
            @change="handlerChange"
          >
            <a-tab-pane v-for="item in statusType" :key="item.id" :tab="item.name"></a-tab-pane>
          </a-tabs>
        </div>
      </template>

      <template #order_no="{text}">
        <a-button class="p-0" type="link" @click="handlerOrderDetail(text)">{{text}}</a-button>
      </template>
      <template #operation="{record}">
        <a-button class="p-0 ml-2 mr-2" type="link" @click="handlerDetail(record)">详情</a-button>
        <a-button class="p-0 ml-2 mr-2" type="link" @click="handlerRefund(record)"
            v-if="record.audit_status == 1" >处理</a-button>
      </template>
    </base-table>

    <RefundAudit v-if="isShowRefund" :show.sync="isShowRefund" :id="activeRow.id" :orderNo="activeRow.order_no" />
    <RefundDetail v-if="isShowDetail" :show.sync="isShowDetail" :id="activeRow.id" :orderNo="activeRow.order_no" />
  </div>
</template>

<script>
import { getStaffList } from "@/api/customer.js"
import { getRefundList } from "@/api/refund.js"
import moment from "moment"
import pageData from "./columns"
import RefundAudit from "./components/refund-audit"
import RefundDetail from "./components/refund-detail"
export default {
  components: { RefundAudit, RefundDetail },
  data() {
    return {
      ...pageData,
      filterColumns: [],
      staffList: [],
      // 是否显示详情
      isShowDetail: false,
      // 是否显示退款
      isShowRefund: false,
      isChecked: false,
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      total: 0,
      searchForm: {
        // 审核状态（0-全部，1-未审核，2-审核通过，3-拒绝，5-已退款）
        audit_status: 0,
        apply_time_arr: [],
        apply_time_start: "",
        apply_time_end: "",
        audit_time_arr: [],
        audit_time_start: "",
        audit_time_end: "",
        page: 1,
        page_count: 10,
        reviewer_staff_id:undefined,
      },
      rowKeys: [],
      isShowEdit: false,
      isShowEditCurrent: false,
      
      activeRow: {},
      tableData: [],
    }
  },
  mounted() {
    this.handlerChange()
  },
  methods: {
    moment,
    formatStatus(id) {
      return this.typeList.find((el) => el.id === id).name
    },

    handlerChange() {
      this.searchForm.page = 1

      // 审核状态（0-全部，1-未审核，2-审核通过，3-拒绝，5-已退款）
      if(this.searchForm.audit_status == 0 || this.searchForm.audit_status == 5){
        this.filterColumns = this.columns
      }
      if(this.searchForm.audit_status == 1){
        this.filterColumns = this.columns.filter(
          (item) =>!["refund_time","audit_time","reviewer"].includes(item.dataIndex)
        )
      }
      if(this.searchForm.audit_status == 2 || this.searchForm.audit_status == 3){
        this.filterColumns = this.columns.filter(
          (item) =>!["refund_time"].includes(item.dataIndex)
        )
      }

      this.initData()
    },

    async initData() {
      if (this.searchForm.apply_time_arr.length > 0) {
        this.searchForm.apply_time_start = this.searchForm.apply_time_arr[0]
        this.searchForm.apply_time_end = this.searchForm.apply_time_arr[1]
      } else {
        this.searchForm.apply_time_start = ""
        this.searchForm.apply_time_end = ""
      }
      if (this.searchForm.audit_time_arr.length > 0) {
        this.searchForm.audit_time_start = this.searchForm.audit_time_arr[0]
        this.searchForm.audit_time_end = this.searchForm.audit_time_arr[1]
      } else {
        this.searchForm.audit_time_start = ""
        this.searchForm.audit_time_end = ""
      }
      const { data, code } = await getRefundList(this.searchForm)
      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },
    // 操作人
    async getStaffList(value) {
      if (value) {
        const { data, code } = await getStaffList({
          staff_name: value,
        })
        if (code === 0) this.staffList = data.list
      }
    },

    // 重置
    resetForm() {
      this.$refs.searchRef.resetFields()
      this.initData()
    },
    // 详情
    handlerDetail(row) {
      this.activeRow = row
      this.isShowDetail = true
    },
    // 退款
    handlerRefund(row) {
      this.activeRow = row
      this.isShowRefund = true
    },

    // 查看订单详情
    handlerOrderDetail(order_no) {
      const newPage = this.$router.resolve({
        name: "order-detail",
        query: { 
          order_no: order_no,
        },
      });
      window.open(newPage.href, "_blank");
    },
  },
}
</script>

<style lang="less">
.goods-search-bar {
  margin-bottom: 16px;
  padding: 15px 24px 0;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
</style>