export const rowKey = "order_no";

export const columns = [
  // 门店列表
  {
    title: "订单ID",
    dataIndex: "order_no",
    align: "center",
    width: 170,
    slots: { customRender: "order_no" },
  },
  {
    title: "申请时间",
    dataIndex: "apply_time",
    align: "center",
  },
  {
    title: "审核时间",
    dataIndex: "audit_time",
    align: "center",
  },
  {
    title: "退款时间",
    dataIndex: "refund_time",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "audit_status",
    align: "center",
    width: "80px",
    slots: {
      customRender: 'audit_status'
    },
    slotsType: 'format',
    slotsFunc: (val) => formatStatus(val)
  },
  {
    title: "审核人",
    dataIndex: "reviewer",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: "110px",
    slots: { customRender: "operation" },
  },
];
// 0-全部，1-未审核，2-审核通过，3-拒绝，5-已退款
export const statusType = [
  { id: 0, name: "全部" },
  { id: 1, name: "未审核" },
  { id: 2, name: "审核通过" },
  { id: 3, name: "拒绝" },
  { id: 5, name: "已退款" },

]

export function formatStatus(val) {
  return statusType.find(el=>el.id ===val).name
}

export default {
  rowKey,
  columns,
  statusType,
};
